@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: white;
    margin: 0;
    padding: 0;
    overscroll-behavior: none;
}


@layer base {
    html {
        font-family: "Ubuntu";
    }
}

